*:not(text) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1rem;
  @media (max-width: 55rem) {
    font-size: 0.75rem;
  }
}

*:focus {
  box-shadow: none !important;
}

html {
  font-size: 13px;
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}

.MuiSvgIcon-root {
  font-size: 1rem !important;
}

body {
  background: white;
  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  min-height: 100vh;
}

@media only screen and (max-width: 55rem) {
  .rmsc {
    --rmsc-h: 1.5rem;
  }
  .rmsc > .dropdown-container {
    --rmsc-h: 1.5rem;
  }
}

@media only screen and (min-width: 55rem) {
  .rmsc {
    --rmsc-h: 2rem;
  }
  .rmsc > .dropdown-container {
    --rmsc-h: 2rem;
  }
}

.toolbarClassName {
  /* background-color: #edf2f7; */
  border-bottom: 0.125rem solid #edf2f7;
}

.toolbarClassName > div > div {
  /* background-color: #edf2f7; */
  /* border: 0.0625rem solid black; */
}

.editorClassName {
  padding: 0rem 1rem;
  margin: 0;
  min-height: 6.25rem;
}

.wrapperClassName {
  padding: 0;
  margin: 0;
  border-radius: 0.2rem;
}

.chakra-popover .chakra-popover__content:focus {
  outline: none;
  box-shadow: none;
}

.multiselectInterview > div {
  background-color: #edf2f7 !important;
  border: none !important;
  color: black;
  font-weight: 600;
  opacity: 1;
  height: 2.5rem;
  padding: 0;
}

.multiselectInterview > div > div {
  height: 2.5rem !important;
  padding: 0 0.625rem 0 1rem !important;
}

.multiselectInterview span {
  color: black !important;
  font-size: 1rem;
}

.react-grid-Main {
  height: 100%;
}

.react-grid-Grid {
  height: 100%;
}

.react-grid-Canvas {
  height: 100% !important;
}